import { useEffect, useState } from "react";
import Form from "./pages/Form";

//
function App() {
  const [counter, setCounter] = useState(0);

  return (
    <div className="app">
      <Form />
    </div>
  );
}

export default App;
